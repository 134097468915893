// components/PriceTag.js
import { WalletIcon } from "@heroicons/react/24/outline";
import React from "react";

const PriceTag = ({ price }) => {
  return (
    <div className="flex items-center my-2 justify-between space-x-4">
      <div className="flex-shrink-0">
        <WalletIcon className="h-6 w-6 text-blue-500" />
      </div>
      <div className="inline-block bg-[#DCF0FA] text-[#1a202c] py-2 px-2 rounded-lg font-semibold text-lg">
        € <span className="text-2xl font-bold font-mono">{price}</span>
      </div>
    </div>
  );
};

export default PriceTag;

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BusinessForm from "../components/BusinessForm";
import DaypartsWidget from "../components/DayParts";
import Description from "../components/Description";
import PrivateForm from "../components/PrivateForm";

export default function Course({ match }) {
  const [course, setCourse] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [cost, setCost] = useState({});
  const [users, setUsers] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/course`, {
          params: { id: id },
        })
        .then((response) => {
          setCourse(response.data);
        })
        .catch((error) => {
          console.error("Error fetching course:", error);
        });
    }
  }, [id]);

  if (!course) return <div>Loading...</div>;

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container mx-auto p-4 my-6">
      <div className=" md:grid md:grid-cols-[70%_minmax(30%,_1fr)_100px] gap-2">
        <div>
          {" "}
          <h2 className="text-[2em] leading-5 my-[1em] ">{course.name}</h2>
          <Description description={course.description} />
          <div className="my-[2em] shadow-md bg-[#DCF0FA] p-4 rounded-xl md:hidden">
            <div className="my-4 ">
              <p className="mt-4 text-2xl">Cursusdetails</p>
              <DaypartsWidget
                course={course}
                handleCost={(data) => setCost(data)}
                handleUser={(data)=>setUsers(data)}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row flex-grow md:p-4 p-1">
            <div className="w-full flex-grow ">
              <div className="w-full border-b border-primary">
                <div className="lg:w-1/2 flex mt-4 ">
                  {" "}
                  <button
                    className={`flex-1 p-2 capitalize ${
                      activeTab === 1
                        ? "bg-primary text-white rounded-t-lg "
                        : "text-gray-600"
                    }`}
                    onClick={() => handleTabChange(1)}
                  >
                    Bedrijf
                  </button>
                  <button
                    className={`flex-1 capitalize p-2 ${
                      activeTab === 2
                        ? "bg-primary text-white rounded-t-lg"
                        : "text-gray-600"
                    }`}
                    onClick={() => handleTabChange(2)}
                  >
                    particulier
                  </button>
                </div>{" "}
              </div>

              <div className="bg-white shadow-xl w-full rounded">
                {activeTab === 1 && <BusinessForm courseId={id} cost={cost} users={users}/>}
                {activeTab === 2 && <PrivateForm courseId={id} cost={cost} users={users}/>}
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div className="my-[2em] shadow-md bg-[#DCF0FA] p-4 rounded-xl md:block hidden">
            <div className="my-4 ">
              <p className="mt-4 text-2xl">Cursusdetails</p>
              <DaypartsWidget
                course={course}
                handleCost={(data) => setCost(data)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function StudentsTable({ students }) {
  return (
    <div className=" bg-[#DCF0FA] flex justify-center items-center rounded">
      <div className="overflow-x-auto w-full max-w-4xl">
        <table className="min-w-full  shadow-md rounded-lg overflow-hidden">
          <thead className="bg-[#c3e1ef]">
            <tr>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Voornaam
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Achternaam
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Tussenvoegsels
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              E-mail
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Geboortedatum
              </th>
            </tr>
          </thead>
          <tbody>
            {students?.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center py-3 px-4">
                Voeg studenten toe
                </td>
              </tr>
            ) : (
              students?.map((student,index) => (
                <tr key={index} className="border-t">
                  <td className="text-left py-3 px-4">{student.first_name}</td>
                  <td className="text-left py-3 px-4">{student.last_name}</td>
                  <td className="text-left py-3 px-4">{student.maiden_name}</td>
                  <td className="text-left py-3 px-4 lowercase">{student.email}</td>
                  <td className="text-left py-3 px-4">{student.date_of_birth}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

export default function RegSuccess() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };
  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-primary mb-4">Welkom bij V.M.T.A.</h1>
        <p className="text-gray-700 mb-6">
          Hartelijk dank voor je enthousiasme en inschrijving voor een van onze cursussen! We zijn verheugd je te mogen verwelkomen.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mb-2">WAT KUN JE NU VERWACHTEN?</h2>
        <p className="text-gray-700 mb-4">
          <strong>Toegang tot de cursus:</strong> Je ontvangt binnenkort een e-mail met instructies over hoe je toegang krijgt tot de cursusmaterialen en de specifieke kenmerken van de cursus.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Ondersteuning:</strong> Heb je vragen? Aarzel niet om contact op te nemen met ons via <a href="mailto:info@vmta.nl" className="text-primary font-semibold">info@vmta.nl</a>
        </p>
        <p className="text-gray-700 mb-6">
          We kijken ernaar uit om samen met jou te leren!
        </p>
        <p className="text-gray-800 font-semibold mb-6">
          WEBTEAM – V.M.T.A.
        </p>
        <button
          className="mt-4 border border-primary hover:bg-primary text-gray-600 hover:text-white font-bold py-2 px-4 rounded"
          onClick={handleGoBack}
        >
          Ga naar cursuslijst
        </button>
      </div>
    </div>
    
  );
}

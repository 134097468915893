// components/DaypartsWidget.js
import DayPart from "./DayPart";

const DaypartsWidget = ({ course, handleCost, handleUser }) => {
  return (
    <div className="p-4 max-w-sm   rounded-xl  space-y-4 w-full">
      {course?.dayparts?.map((daypart, index) => (
        <DayPart
          key={index}
          costs={course?.costs?.user}
          daypart={daypart}
          handleCost={handleCost}
          handleUser={handleUser}
        />
      ))}
    </div>
  );
};

export default DaypartsWidget;

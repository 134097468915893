import React from "react";

export default function DataComponent({ icon: Icon, data }) {
  return (
    <div className="flex items-center my-2 justify-between space-x-4">
      <div className="flex-shrink-0">
        {Icon && <Icon className="h-6 w-6 text-blue-500" />}
      </div>
      <div className="text-gray-700">{data}</div>
    </div>
  );
}

import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import IconDataComponent from "./DataComponent";
import {
  CalendarIcon,
  ClockIcon,
  LockClosedIcon,
  LockOpenIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import LocationData from "./LocationData";
import { DateTime } from "luxon";
import PriceTag from "./PriceTag";

export default function DayPart({
  daypart = {},
  hide = false,
  handleCost,
  handleUser,
  costs
}) {
  const [data, setData] = useState(null);

  const { id } = daypart;
  const memoizedHandleCost = useCallback(handleCost, []);
  const memoizedHandleUser = useCallback(handleUser, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/dayparts`, {
          params: { id: id },
        })
        .then((res) => {
          setData(res.data);
          memoizedHandleCost(res.data.costs);
          memoizedHandleUser(res.data.users);
        })
        .catch((error) => {
          console.error("Error fetching course:", error);
        });
    }
  }, [id, memoizedHandleCost,memoizedHandleUser]);

  return (
    <div key={daypart.id} className="flex flex-col items-start justify-start ">
      <IconDataComponent
        icon={CalendarIcon}
        data={`${DateTime.fromISO(data?.date).toLocaleString(DateTime.DATE_MED)}`}
      />
      <IconDataComponent
        icon={ClockIcon}
        data={`${data?.start_time}-${data?.end_time}`}
      />
      <IconDataComponent
        icon={NoSymbolIcon}
        data={`Groepsgrootte ${data?.users?.limit}`}
      />
      <IconDataComponent
        icon={LockOpenIcon}
        data={`${data?.users?.available} plaatsen beschikbaar`}
      />
      {/* <IconDataComponent
        icon={LockClosedIcon}
        data={`${data?.users?.scheduled} genomen`}
      /> */}
      {hide ? (
        <></>
      ) : (
        <>
          {" "}
          <PriceTag price={costs} />
          <LocationData data={data?.locations[0]} />
        </>
      )}
    </div>
  );
}

import React from "react";
import DOMPurify from "dompurify";

export default function Description({ description }) {
  let sanitizedDescription;
  if (description) {
    sanitizedDescription = DOMPurify.sanitize(description);
  }

  return (
    <div
      className="subpixel-antialiased tracking-wide leading-7"
      dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
    />
  );
}

import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useEffect, useState } from "react";
import CourseCard from "../components/CourseCard";
import InputForm from "../components/InputForm";

export default function Courses() {
  const getCurrentMonthStart = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const getNextMonthEnd = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
  };
  const [courses, setCourses] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [startDate, setStartDate] = useState(getCurrentMonthStart());
  const [endDate, setEndDate] = useState(getNextMonthEnd());
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/templates`, {
        headers: {
          "x-api-key":
            "27F3E5C37B37CB4B1A1BD3C42AFE03901E0CBC40D0071F23ACE2892382F76D77",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTemplates(response.data.coursetemplates);
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/api/courses`,

        {
          params: {
            start: startDate,
            end: endDate,
            templateid: selectedTemplate,
          },
          headers: {
            "x-api-key":
              "27F3E5C37B37CB4B1A1BD3C42AFE03901E0CBC40D0071F23ACE2892382F76D77",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setCourses(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, [startDate, endDate, selectedTemplate]);
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
      Kies een van onze trainingen en maak een boeking.
      </h1>
      <div className="flex justify-between items-center p-4 bg-[#b7e6ff] rounded my-6 space-x-4">
        <InputForm
          type={"date"}
          label={"Begin datum"}
          className={"w-full"}
          onChange={(e) => setStartDate(e.target.value)}
        />

        <InputForm
          type={"date"}
          label={"Einddatum"}
          className={"w-full"}
          onChange={(e) => setEndDate(e.target.value)}
        />

        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">
          Categorie
          </label>
          <select
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
          >
            <option value="">Selecteer een cursuscategorie</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex  my-6 justify-end items-end">
        <AdjustmentsHorizontalIcon className="size-7 cursor-pointer " />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {courses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </div>
  );
}

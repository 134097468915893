import React from "react";

export default function InputForm({
  type,
  placeholder,
  required,
  name,
  label,
  prefix,
  sublabel,
  checked,
  className,
  onChange,
}) {
  return (
    <div
      className={"flex flex-col items-start justify-between gap-1 " + className}
    >
      <div className="flex items-center justify-center space-x-1">
        <label htmlFor="">{label}</label>
        {required && <span className="text-red-500 text-lg">*</span>}
      </div>

      <div className="rounded bg-[#F4F4F4]  w-full flex items-center">
        {prefix && prefix}
        <input
          type={type}
          placeholder={placeholder}
          required={required}
          name={name}
          checked={checked}
          className="rounded w-full bg-[#F4F4F4]  py-3 px-2 focus:outline-none"
          onChange={onChange}
        />
      </div>
      <div className="flex items-center justify-center space-x-1">
        <p className="text-xs text-gray-400">{sublabel}</p>
      </div>
    </div>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Courses from "./pages/Courses";
import Course from "./pages/Course";
import RegSuccess from "./pages/RegistrationSuccess";

function App() {
  return (
    <BrowserRouter>
      <div className="flex justify-center items-center">
        <Routes>
          <Route exact path="/" element={<Courses />} />
          <Route path="/courses/:id" element={<Course />} />
          <Route path="/thank-you" element={<RegSuccess />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

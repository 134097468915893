import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DayPart from "./DayPart";
import { LockClosedIcon } from "@heroicons/react/24/outline";

export default function CourseCard({ course }) {
  const [cost, setCost] = useState({});
  const [users, setUsers] = useState({});
  let navigate = useNavigate();

  const handleClick = () => {
    navigate(`/courses/${course?.id}`);
  };

  return (
    <div key={course?.id} className="bg-white px-2 py-1  rounded-lg shadow-lg">
      <Link to={`courses/${course?.id}`} className="" state={{ id: course.id }}>
        {" "}
        <h2 className="text-lg font-semibold my-2 mx-1 hover:text-[#FF9140]">
          {course?.name}
        </h2>
      </Link>
      {users.available === 0 ? (
        <p className="text-primary text-sm uppercase">Cursus is al vol</p>
      ) : (
        <></>
      )}
      <p></p>
      <div className="px-4 py-1 max-w-sm   rounded-xl  space-y-4 w-full">
        {course?.dayparts?.map((daypart, index) => (
          <DayPart
            key={index}
            daypart={daypart}
            hide={true}
            costs={course?.costs?.user}
            handleCost={(data) => setCost(data)}
            handleUser={(data) => setUsers(data)}
          />
        ))}
      </div>
      <div className="flex justify-between items-center bottom-0">
        <div className="">
          <div className="inline-block bg-[#DCF0FA] text-[#1a202c] py-2 px-4 rounded-lg font-semibold text-lg">
            €{" "}
            <span className="text-2xl font-bold font-mono">
              {course.costs?.user}
            </span>
          </div>
        </div>
        {users.available === 0 ? (
          <div
            onClick={() => {}}
            className="flex justify-between items-center gap-4 p-2 hover:bg-primary  hover:text-white border border-primary  rounded cursor-pointer"
          >
            <p>Cursus is al vol</p>
          </div>
        ) : (
          <div
            onClick={handleClick}
            className="flex justify-between items-center gap-4 p-2 hover:bg-primary  hover:text-white border border-primary  rounded cursor-pointer"
          >
            <span>Inschrijven</span>{" "}
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import InputSelect from "./InputSelect";
import InputForm from "./InputForm";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function PrivateForm({ courseId, users }) {
  const [isChecked, setIsChecked] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [studentFormValues, setStudentFormValues] = useState({
    gender: "",
    initials: "",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    house_number: "",
    house_number_extension: "",
    postal_code: "",
    city: "",
    phonenumber: "",
    date_of_birth: "",
    maiden_name: "",
  });
  const navigate = useNavigate();
  const genders = [
    { key: "m", value: "Man" },
    { key: "f", value: "Vrouw" },
    { key: "null", value: "Anders" },
  ];

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/booking`,
        {
          students: [studentFormValues],
          course_id: courseId,
        },
        { params: { course_id: courseId } }
      )
      .then((response) => {
        setConfirmation("Registratie gelukt");
        navigate("/thank-you");
      })
      .catch((error) => {
        console.error("Error processing booking:", error);
      });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentFormValues({
      ...studentFormValues,
      [name]: value,
    });
  };
  return (
    <div className="py-4 px-2 capitalize">
      <form onSubmit={handleSubmit}>
        <div className="mx-6 my-3">
          <InputSelect
            label={"Geslacht"}
            placeholder={"Geslacht?"}
            required={true}
            options={genders}
            name="gender"
            value={studentFormValues.gender}
            onChange={handleInputChange}
          />
          {/* name */}
          <div className="my-4">
            <div className="flex items-start justify-start space-x-1 normal-case">
              <label htmlFor="">Voor-en achternaam</label>
              <span className="text-red-500 text-lg">*</span>
            </div>
            <div className="flex items-center space-x-2">
              <InputForm
                type={"text"}
                sublabel={"Initialen"}
                name="initials"
                value={studentFormValues.initials}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Voornaam"}
                name="first_name"
                value={studentFormValues.first_name}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Tussenvoegsels"}
                name="maiden_name"
                value={studentFormValues.maiden_name}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Achternaam"}
                name="last_name"
                value={studentFormValues.last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>{" "}
          {/* address */}
          <div className="w-full">
            <div className="flex items-start justify-start space-x-1">
              <label htmlFor="">Adres</label>
              <span className="text-red-500 text-lg">*</span>
            </div>
            <div className="flex items-start  md:items-center space-x-2 flex-wrap md:flex-nowrap w-full">
              <InputForm
                type={"text"}
                sublabel={"Adres"}
                name="address"
                value={studentFormValues.address}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"huisnummer"}
                name="house_number"
                value={studentFormValues.house_number}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Uitbreiding huisnummer"}
                name="house_number_extension"
                value={studentFormValues.house_number_extension}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex items-center space-x-2 w-full">
              <InputForm
                type={"text"}
                sublabel={"postcode"}
                name="postal_code"
                value={studentFormValues.postal_code}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"stad"}
                name="city"
                value={studentFormValues.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* email and phone */}
          <div className="flex justify-start items-center space-x-2 my-4">
            <InputForm
              type={"text"}
              label={"e-mailadres"}
              prefix={<EnvelopeIcon className="h-6 w-6 mx-2" />}
              required={true}
              name="email"
              value={studentFormValues.email}
              onChange={handleInputChange}
            />
            <InputForm
              type={"text"}
              label={"Telefoonnummer"}
              prefix={<DevicePhoneMobileIcon className="h-6 w-6 mx-2" />}
              required={true}
              name="phonenumber"
              value={studentFormValues.phonenumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="my-2">
            {" "}
            <InputForm
              type={"date"}
              label={"Geboortedatum"}
              required={true}
              name="date_of_birth"
              value={studentFormValues.date_of_birth}
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="my-2">
            <InputSelect
              label={"Choose Payment method"}
              placeholder={"organization"}
              required={true}
              options={paymentmethods}
            />
          </div>
          <div className="my-4">
            <p className="mt-4 text-lg">Total (incl. VAT)</p>
            <div className="inline-block bg-[#DCF0FA] text-[#1a202c] py-2 px-4 rounded-lg font-semibold text-lg">
              € <span className="text-2xl font-bold font-mono">180</span>
            </div>
          </div> */}
          <div className="mx-2">
            <p className="mt-4 text-lg">algemene voorwaarden</p>
            <div className="mx-6 my-3">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox text-primary h-5 w-5"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  required
                />
                <span className="ml-2 normal-case">
                  Ik ga akkoord met de{" "}
                  <a href="/terms" className="text-[#FF9140]">
                    voorwaarden
                  </a>
                </span>
              </label>{" "}
            </div>
          </div>
          <div className="mx-2">
            {users.available === 0 ? (
              <button
                onClick={() => {}}
                className="flex justify-between items-center gap-4 p-2 hover:bg-primary  hover:text-white border border-primary  rounded cursor-pointer"
              >
                <p>Course is al vol</p>
              </button>
            ) : (
              <button
                type="submit"
                className="mt-4 border border-primary hover:bg-primary text-gray-600 hover:text-white font-bold py-2 px-4 rounded uppercase"
              >
                Inschrijven
              </button>
            )}
          </div>
        </div>
        {confirmation && (
          <div className="p-4 bg-emerald-300 rounded mx-auto">
            {confirmation}
          </div>
        )}
      </form>
    </div>
  );
}

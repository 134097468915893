import axios from "axios";
import React, { useEffect, useState } from "react";
import IconDataComponent from "./DataComponent";
import { MapPinIcon } from "@heroicons/react/24/outline";

export default function LocationData({ data }) {
  const [location, setLocations] = useState({});
  useEffect(() => {
    if (data) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/locations`, {
          params: { id: data.id },
        })
        .then((res) => {
          setLocations(res.data);
        })
        .catch((error) => {
          console.error("Error fetching course:", error);
        });
    }
  }, [data]);

  return (
    <div>
      <p className="my-4 text-2xl capitalize">Cursuslocatie</p>

      <div>
        <IconDataComponent
          icon={MapPinIcon}
          data={`${location.name}, ${location?.address?.street_1}, ${location?.address?.housenumber}, ${location?.address?.city}`}
        />
      </div>
    </div>
  );
}

import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputForm from "./InputForm";
import InputSelect from "./InputSelect";
import Modal from "./Modal";
import StudentsTable from "./StudentsTable";

export default function BusinessForm({ courseId, users }) {
  const [isChecked, setIsChecked] = useState(false);
  const [students, setStudents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [studentFormValues, setStudentFormValues] = useState({
    gender: "",
    initials: "",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    house_number: "",
    house_number_extension: "",
    postal_code: "",
    city: "",
    phonenumber: "",
    date_of_birth: "",
    maiden_name: "",
  });
  const [company, setCompany] = useState({
    name: "",
    email: "",
    address: "",
    house_number: "",
    house_number_extension: "",
    postal_code: "",
    city: "",
    phonenumber: "",
  });
  const navigate = useNavigate();
  const genders = [
    { key: "m", value: "Man" },
    { key: "f", value: "Vrouw" },
    { key: "null", value: "Anders" },
  ];
  // const paymentmethods = [
  //   { key: "invoice", value: "Invoice" },
  //   { key: "ideal", value: "iDEAL" },
  // ];
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentFormValues({
      ...studentFormValues,
      [name]: value,
    });
  };
  const addStudent = (e) => {
    e.preventDefault();
    setStudents([...students, studentFormValues]);
    setStudentFormValues({
      gender: "",
      initials: "",
      first_name: "",
      nick_name: "",
      last_name: "",
      email: "",
      address: "",
      house_number: "",
      house_number_extension: "",
      postal_code: "",
      city: "",
      country: "",
      phonenumber: "",
      date_of_birth: "",
      maiden_name: "",
    });
    handleModal();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/booking`,
        {
          students: students,
          company: company,
          course_id: courseId,
        },
        { params: { course_id: courseId } }
      )
      .then((response) => {
        // setConfirmation("Registratie gelukt");
        navigate("/thank-you");
      })
      .catch((error) => {
        console.error("Error processing booking:", error);
      });
  };
  return (
    <div className="py-4 px-3 min-h-screen capitalize">
      <form onSubmit={handleSubmit}>
        <div className="mx-2">
          <p className="mt-4 text-2xl normal-case">Details van de organisatie</p>
          <div className="mx-6 my-3">
            <InputForm
              type={"text"}
              label={"organisatienaam"}
              placeholder={"organisatie"}
              required={true}
              name="name"
              value={company.name}
              onChange={handleChange}
            />
            {/* */}
            <InputForm
              type={"text"}
              label={"Telefoonnummer"}
              prefix={<DevicePhoneMobileIcon className="h-6 w-6 mx-2" />}
              required={true}
              name="phonenumber"
              value={company.phonenumber}
              onChange={handleChange}
            />
            <InputForm
              type={"email"}
              label={"e-mailadres"}
              prefix={<EnvelopeIcon className="h-6 w-6 mx-2" />}
              required={true}
              name="email"
              value={company.email}
              onChange={handleChange}
            />
            <div className="w-full">
              <div className="flex items-start justify-start space-x-1">
                <label htmlFor="">Adres</label>
                <span className="text-red-500 text-lg">*</span>
              </div>
              <div className="flex items-start  md:items-center space-x-2 flex-wrap md:flex-nowrap w-full">
                <InputForm
                  type={"text"}
                  sublabel={"Adres"}
                  name="address"
                  value={company.address}
                  onChange={handleChange}
                />
                <InputForm
                  type={"text"}
                  sublabel={"huisnummer"}
                  name="house_number"
                  value={company.house_number}
                  onChange={handleChange}
                />
                <InputForm
                  type={"text"}
                  sublabel={"Uitbreiding huisnummer"}
                  name="house_number_extension"
                  value={company.house_number_extension}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center space-x-2 w-full">
                <InputForm
                  type={"text"}
                  sublabel={"Postcode"}
                  name="postal_code"
                  value={company.postal_code}
                  onChange={handleChange}
                />
                <InputForm
                  type={"text"}
                  sublabel={"stad"}
                  name="city"
                  value={company.city}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mx-2">
          <p className="mt-4 text-2xl">Cursisten</p>
          <div className="mx-6 my-3">
            <p className="mt-4 text-lg"> Cursist toevoegen</p>
            <StudentsTable students={students} />
            <button
              type="button"
              className="mt-4 border border-primary hover:bg-primary text-gray-600 hover:text-white font-bold py-2 px-4 rounded"
              onClick={handleModal}
            >
              Cursist toevoegen
            </button>
          </div>
        </div>
        <div className="mx-2">
          <p className="mt-4 text-lg">algemene voorwaarden</p>
          <div className="mx-6 my-3">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-[#FF9140] h-5 w-5"
                checked={isChecked}
                onChange={handleCheckboxChange}
                required
              />
              <span className="ml-2 normal-case">
                Ik ga akkoord met de
                <a href="/terms" className="text-[#FF9140]">
                  voorwaarden
                </a>
              </span>
            </label>{" "}
          </div>
        </div>
        <div className="mx-2">
          {users.available === 0 ? (
            <button
              onClick={() => {}}
              className="flex justify-between items-center gap-4 p-2 hover:bg-primary  hover:text-white border border-primary  rounded cursor-pointer"
            >
              <p>Course is al vol</p>
            </button>
          ) : (
            <button
              type="submit"
              className="mt-4 border border-primary hover:bg-primary text-gray-600 hover:text-white font-semibold py-2 px-4 rounded uppercase"
            >
              Inschrijven{" "}
            </button>
          )}
        </div>
      </form>
      {confirmation && <p>{confirmation}</p>}
      <Modal title={"Cursist toevoegen"} onClose={handleModal} isOpen={isOpen}>
        <form onSubmit={addStudent} >
          <InputSelect
            label={"Geslacht"}
            placeholder={"Geslacht?"}
            required={true}
            options={genders}
            name="gender"
            value={studentFormValues.gender}
            onChange={handleInputChange}
          />
          <div className="my-4">
            <div className="flex items-start justify-start space-x-1">
              <label htmlFor="">Naam</label>
              <span className="text-red-500 text-lg">*</span>
            </div>
            <div className="flex items-center space-x-2">
              <InputForm
                type={"text"}
                sublabel={"Initialen"}
                name="initials"
                value={studentFormValues.initials}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Voornaam"}
                name="first_name"
                value={studentFormValues.first_name}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Tussenvoegsels"}
                name="maiden_name"
                value={studentFormValues.maiden_name}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Achternaam"}
                name="last_name"
                value={studentFormValues.last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>{" "}
          <div className="flex justify-start items-center space-x-2 my-4">
            <InputForm
              type={"date"}
              label={"Geboortedatum"}
              // prefix={<DevicePhoneMobileIcon className="h-6 w-6 mx-2" />}
              required={true}
              className={"w-full"}
              name="date_of_birth"
              value={studentFormValues.date_of_birth}
              onChange={handleInputChange}
            />
            <InputForm
              type={"email"}
              label={"e-mailadres"}
              prefix={<EnvelopeIcon className="h-6 w-6 mx-2" />}
              required={true}
              className={"w-full"}
              name="email"
              value={studentFormValues.email}
              onChange={handleInputChange}
            />
            <InputForm
              type={"text"}
              label={"Telefoon nummer"}
              name="phonenumber"
              className={"w-full"}
              value={studentFormValues.phonenumber}
              onChange={handleInputChange}
              prefix={<DevicePhoneMobileIcon className="h-6 w-6 mx-2" />}
            />
          </div>
          <div className="w-full">
            <div className="flex items-start justify-start space-x-1">
              <label htmlFor="">Adres</label>
              {/* <span className="text-red-500 text-lg">*</span> */}
            </div>
            <div className="flex items-start  md:items-center space-x-2 flex-wrap md:flex-nowrap w-full">
              <InputForm
                type={"text"}
                sublabel={"Adres"}
                name="address"
                value={studentFormValues.address}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Huisnummer"}
                name="house_number"
                value={studentFormValues.house_number}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"Uitbreiding huisnummer"}
                name="house_number_extension"
                value={studentFormValues.house_number_extension}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex items-center space-x-2 w-full">
              <InputForm
                type={"text"}
                sublabel={"postcode"}
                className={"w-full"}
                name="postal_code"
                value={studentFormValues.postal_code}
                onChange={handleInputChange}
              />
              <InputForm
                type={"text"}
                sublabel={"stad"}
                className={"w-full"}
                name="city"
                value={studentFormValues.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4">
            <button
              type="button"
              className="mt-4 border border-secondary hover:bg-secondary text-gray-600 hover:text-white font-bold py-2 px-4 rounded capitalize"
              onClick={handleModal}
            >
              annuleren
            </button>
            <button
              type="submit"
              className="mt-4 border border-primary hover:bg-primary text-gray-600 hover:text-white font-bold py-2 px-4 rounded"
            >
              Cursist toevoegen
            </button>
          </div>
          {confirmation && (
            <div className="p-4 bg-emerald-300 rounded mx-auto">
              {confirmation}
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
}

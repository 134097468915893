export default function InputSelect({
  options,
  required,
  name,
  label,
  onChange,
  placeholder,
}) {
  return (
    <div className="flex flex-col items-start justify-between gap-1 ">
      <div className="flex items-center justify-center space-x-1">
        <label htmlFor="">{label}</label>
        {required && <span className="text-red-500 text-lg">*</span>}
      </div>

      <div className="rounded bg-[#F4F4F4]  w-full flex items-center">
        <select
          name={name}
          required={required}
          onChange={onChange}
          className="rounded w-full bg-[#F4F4F4]  py-3 px-2 focus:outline-none"
        >
          <option value="">{placeholder}</option>
          {options?.map((option) => {
            return (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
